import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Select, Input, Checkbox, Text } from "../components/Core";

import PageWrapper from "../components/PageWrapper";

import Hero from "../sections/common/Hero";
import { post } from 'axios';

import dataService from "../services/data-service";
import imgIcon from "../assets/image/png/thumbs-shape.png";

const FormStyled = styled.form``;

// const adTypes = [
//   { value: "", label: "Select posting type" },
//   { value: "Roomate", label: "Roomate" },
//   { value: "House/Apt for rent", label: "House/Apt for rent" },
//   { value: "Misc", label: "Misc" },
//   { value: "Wanted", label: "Wanted" },
//   { value: "Computers", label: "Computers" },
//   { value: "Auto", label: "Auto" },
//   { value: "Community", label: "Community" }
// ];

const ContentIcon = styled.div`
  width: 118px;
  height: 118px;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 500px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
`;

let classifiedTypeArr = [
  { value: "", label: "Select classified types" },
];

let regionListArr = [
  { value: "", label: "Select city" },
];

class Classified extends React.Component {

  constructor() {
    super();
    this.onFileSelect = this.onFileSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.selectAdChange = this.selectAdChange.bind(this);
    this.selectRegionChange = this.selectRegionChange.bind(this);
    this.getClassifiedTypes = this.getClassifiedTypes.bind(this);
    this.getRegion = this.getRegion.bind(this);

    this.state = {
      data: null,
      file: null,
      alert: {
        display: false,
        message: ''
      }
    };

    this.getClassifiedTypes();
    this.getRegion();
  }

  onFileSelect(e) {
    let files = e.target.files;
    // this.setState({
    //   files: files
    // });

    if (files.length > 0) {
      for (let fl=0; fl < files.length; fl++) {
        const currentFile = files[fl];
        const fileIndex = fl + 1;
        const reader = new FileReader(currentFile);
        reader.readAsDataURL(currentFile);
        reader.onload = () => {
          // set image and base64'd image data in component state
          this.setState({
            [`imageP${fileIndex}`] : {
              data: reader.result,
              name: currentFile.name
            }
          });

          // console.log({
          //   data: reader.result,
          //   name: currentFile.name
          // });
          // this.setState({
          //   data: reader.result,
          //   file: currentFile
          // });
        }
      }
    }
  }

  getRegion = async () => {
    const regionList = await dataService.getRegionList();
    if(regionList.length > 0){
      for (const currentRegion of regionList) {
        regionListArr.push({
          'value': currentRegion.name,
          'label': currentRegion.name,
          'id': currentRegion.objectId
        });
      }
      console.log(" region ", regionListArr);
    }
  };

  getClassifiedTypes = async () => {
    const cfList = await dataService.getClassifiedType();
    if(cfList.length > 0){
      for (const currentCf of cfList) {
        classifiedTypeArr.push({
          'value': currentCf.name,
          'label': currentCf.name,
          'id': currentCf.objectId
        });
      }
      // console.log(" cat ", categoryListArr);
    }
  };

  handleInputChange(event) {
    const target = event.target;
    // const value = target.value;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    // console.log(" target value - ", name, value);
    
    this.setState({
      [name]: value
    });
  }

  selectAdChange(inputVal){
    // console.log(" input val ", inputVal);
    if(inputVal && inputVal.value){
      this.setState({
        adType: inputVal.value
      })
    }
  }

  selectRegionChange(inputVal){
    // console.log(" input val ", inputVal);
    if(inputVal && inputVal.value){
      this.setState({
        regionType: inputVal.value
      })
    }
  }

  renderField = ({
    input,
    label,
    type,
    meta: { touched, error, warning }
  }) => (
    <div>
      <label>{label}</label>
      <div>
        <Input {...input} placeholder={label} type={type} />
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  )
        
  handleSubmit(e) {
    e.preventDefault();
    const { adTitle, adType, zipcode, price, description, showAddress, address, showEmail, phone, email, regionType, imageP1, imageP2, imageP3 } = this.state;

    if(!adType) {
      alert(" Please select Ad type");
      return false;
    }else if(!regionType) {
      alert(" Please select City");
      return false;
    }

    // const url = '';
    const url = 'https://w2wn4ej9j9.execute-api.us-east-1.amazonaws.com/dev/createClassified';
    const formPrice = parseFloat(price) ? parseFloat(price) : 0;
    let formBody = {
      adType,
      adTitle,
      price: formPrice,
      description,
      zipcode
    };
    
    if(showAddress === true){
      formBody.address = address;
    }

    if(showEmail === true){
      formBody.userEmail = email;
    }
    formBody.userPhone = phone;
    formBody.region = regionType;
    

    if(imageP1){
      formBody.imageP1 = imageP1;
    }
    if(imageP2){
      formBody.imageP2 = imageP2;
    }
    if(imageP3){
      formBody.imageP3 = imageP3;
    }

    // console.log(" form body ", formBody);

    const config = {
        headers: {
            'content-type': 'application/json'
        }
    }
    return  post(url, formBody, config)
    .then((submitted) => {
      if(submitted.status === 200){
        console.log(" submitted ", submitted);
        this.setState({
          alert: {
            display: true,
            message: 'Thank you for posting your classified information, it will be reviewed and posted in few hours.'
          }
        });
      }else {
        this.setState({
          alert: {
            display: true,
            message: 'Failed to submit classified.'
          }
        });
      }
    }, (formErr) => {
      console.log(" formErr ", formErr);
      this.setState({
        alert: {
          display: true,
          message: 'Failed to submit classified.'
        }
      });
    });
  }
  

  render() {

    const { showAddress, showEmail, alert } = this.state;

    return (
      <>
        <PageWrapper footerDark>
          <Hero title="Post Classified">
            Please fill below form.
          </Hero>
          <Section pt={0} mt={0}>
            <Container>

              {alert.display === true && (<div className="text-center">
                <ContentIcon>
                  <img src={imgIcon} alt="" className="img-fluid" />
                </ContentIcon>
                <div>
                  <Title variant="hero">Thank You</Title>
                  <Text>
                    {alert.message}
                  </Text>
                </div>
              </div>)}

              {alert.display === false && (<Row className="align-items-center" >
                <Col lg="6">
                  <FormStyled onSubmit={this.handleSubmit} >
                    {/* <Box mb={5}>
                      <Title variant="card">Please fill below form.</Title>
                    </Box> */}
                    <Box mb={3}>
                      <Select name="adType" options={classifiedTypeArr} onChange={this.selectAdChange}  required />
                    </Box>
                    <Box mb={3}>
                      <Input type="text" placeholder="Posting title" name="adTitle" onChange={this.handleInputChange} required />
                    </Box>
                    
                    <Box mb={3}>
                      <Select name="regionType" options={regionListArr} onChange={this.selectRegionChange}  required />
                    </Box>

                    <Box mb={3}>
                      <Input type="text" name="price" placeholder="Price" onChange={this.handleInputChange} />
                    </Box>
                    
                    <Box mb={3}>
                      <Input type="text" name="zipcode" placeholder="Zipcode" onChange={this.handleInputChange} required />
                    </Box>

                    <Box mb={3}>
                      <Input type="text" name="phone" placeholder="Phone" onChange={this.handleInputChange} required/>
                    </Box>

                    <Box mb={3}>
                      <Input
                        type="text"
                        name="description"
                        as="textarea"
                        placeholder="Description"
                        rows={4}
                        onChange={this.handleInputChange}
                        required
                      />
                    </Box>

                    <Box mb={3}>
                      <Checkbox uName="showAddress" onChange={this.handleInputChange}> Show address  </Checkbox>
                    </Box>

                    {showAddress === true && (<Box mb={3}>
                      <Input type="text" name="address" placeholder="Address" onChange={this.handleInputChange} />
                    </Box>)}


                    <Box mb={3}>
                      <Checkbox uName="showEmail" onChange={this.handleInputChange}> Show my Email  </Checkbox>
                    </Box>

                    {showEmail === true && (<Box mb={3}>
                      <Input type="text" name="email" placeholder="Email" onChange={this.handleInputChange} required />
                    </Box>)}



                    <Box mb={3}>
                      <Input type="file" name="photos" placeholder="Photos" multiple onChange={this.onFileSelect} />
                    </Box>

                    <Button width="100%" type="submit" borderRadius={10}>
                      Submit
                    </Button>
                  </FormStyled>
                </Col>

              </Row>)}

            </Container>
          </Section>
        </PageWrapper>
      </>
    );
  }
}

export default Classified;
