/* eslint-disable no-unused-vars */
import axios from "axios";

class dataService {
  constructor() {
    this.initParseInstance();
  }

  // Parse connection configuration
  initParseInstance() {
    var _0x1c71=['\x48\x41\x6d\x59\x76\x52\x48\x6a\x59\x6e\x78\x32\x69\x49\x41\x4c\x58\x52\x58\x70\x58\x37\x65\x4b\x6b\x6f\x4c\x6e\x6a\x59\x6a\x71\x57\x6c\x69\x79\x4c\x54\x4b\x42\x30\x65','\x4f\x77\x65\x79\x79\x48\x37\x78\x7a\x30\x38\x68\x7a\x67\x71\x55\x4f\x68\x59\x71\x69\x4b\x6d\x75\x56\x65\x5a\x6f\x7a\x47\x39\x68\x35\x31\x39\x31\x73\x69\x52\x67','\x52\x73\x74\x46\x50\x57\x4e\x67\x41\x31\x30\x30\x36\x51\x30\x6c\x54\x57\x4a\x49\x4e\x73\x34\x5a\x69\x50\x66\x72\x66\x45\x35\x77\x37\x4f\x69\x6f\x36\x46\x68\x5a\x65\x49\x65'];var _0x31d0=function(_0x1c71e7,_0x31d079){_0x1c71e7=_0x1c71e7-0x0;var _0x3abc62=_0x1c71[_0x1c71e7];return _0x3abc62;};function efrtgh(){var _0x269b95=_0x31d0;return{'\x69\x64':_0x269b95('\x30\x78\x30'),'\x63\x6c\x69\x65\x6e\x74\x4b\x65\x79':_0x269b95('\x30\x78\x31'),'\x72\x65\x73\x74\x4b\x65\x79':_0x269b95('\x30\x78\x32')};}
    var efr = efrtgh();
    this.kestoParse = {
      serverUrl: 'https://paas.kesto.io/apps',
      appId: efr.id,
      clientKey: efr.clientKey,
      restApiKey: efr.restKey
    };

    this.kestoInstance = axios.create({
      baseURL: this.kestoParse.serverUrl,
    });

    this.kestoInstance.defaults.headers.common['X-Parse-Application-Id'] = this.kestoParse.appId;
    this.kestoInstance.defaults.headers.common['X-Parse-REST-API-Key'] = this.kestoParse.restApiKey;
    this.kestoInstance.defaults.headers.common['Content-Type'] = 'application/json';


    // Add a response interceptor
    this.kestoInstance.interceptors.response.use(function (response) {
      if(typeof response === 'object' && typeof response.data === 'object' && typeof response.data.results === 'object'){
        return response.data.results;
      }else {
        return response.data;
      }
    }, function (error) {
      if(typeof error === 'object' && typeof error.response === 'object' && typeof error.response.data === 'object'){
        return Promise.reject(error.response.data.error);
      }else {
        return Promise.reject(error);
      }
    });

  }

  // Get all region list
  async getRegionList() {
    let regionParams = {
      keys: 'name',
      where: {
        enabled: true
      }
    };
    return await this.kestoInstance.get('/classes/Region', {
      params: regionParams
    });
  }

  // Get all category list
  async getCategoryList() {
    let categoryParams = {
      keys: 'name',
      where: {
        enabled: true
      },
      limit: 800
    };
    return await this.kestoInstance.get('/classes/Category', {
      params: categoryParams
    });
  }
  
  // Get all classified type list
  async getClassifiedType() {
    let classifiedParams = {
      keys: 'name',
      where: {
        enabled: true
      },
      limit: 800
    };
    return await this.kestoInstance.get('/classes/classifiedType', {
      params: classifiedParams
    });
  }

  // Captcha verify
  async captchaVerify(cToken) {
    return await this.kestoInstance.post('/functions/captchaVerify', {
      cToken: cToken
    });
  }
}

export default new dataService();